<template>
    <div class="app-view" v-if="userInfo">
        <div class="user-info">
            <div class="avatar">
                <img src="" v-if="userInfo.avatar" alt="" />
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" style="position: absolute;left: -84px;top: 18px;height: 80px;width: 180px;">
                    <defs>
                        <path id="path1" d="M77,20 a1,1 0 0,0 90, 5" />
                        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" style="stop-color:rgb(227,117, 255);stop-opacity:1" />
                            <stop offset="100%" style="stop-color:rgb(14,30,147);stop-opacity:1" />
                        </linearGradient>
                    </defs>
                    <text x="10" y="100" fill="url(#grad1)" style="font-size:13px;font-weight: bold;">
                        <textPath xlink:href="#path1">I love Money Money</textPath>
                    </text>
                </svg>
            </div>
            <div class="user-name">{{ '你好，' + (userInfo.nickname || userInfo.username) }}</div>
        </div>
        <!-- <van-button type="info" @click="getCode">获取授权</van-button> -->
        <van-cell-group>
            <van-cell title="订单列表" is-link to="orderlist" />
            <van-cell title="转换历史" is-link to="history" />
            <van-cell title="个人信息" is-link to="userSetting" />
            <van-cell title="拉取订单数据" is-link to="getOrderData" v-if="userInfo.level==3" />
        </van-cell-group>
        <div style="padding: 50px 10px 10px;">
            <van-button type="default" block @click="logout">退出登录</van-button>
        </div>
        <Navi />
    </div>
</template>

<script>
import Navi from '@/components/Navigator'
export default {
    components: {
        Navi
    },
    data() {
        return {
            userInfo: null
        }
    },
    created() {
        this.initialize()
    },
    methods: {
        initialize() {
            this.userInfo = this.$store.state.user.userInfo;
            // let tmpCode = this.$locationQuery2Json().code
            // if (tmpCode) {
            //     this.$ask.post('/getToken',{sessionCode: tmpCode})
            //         .then(res => {
            //             console.log(res)
            //         })
            //         .catch(this.$toastError)
            // }
        },
        getCode() {
            window.location.href = `https://oauth.m.taobao.com/authorize?response_type=code&client_id=28203884&redirect_uri=https://sugarcube.club/usercenter&state=getcode`
        },
        logout(){
            this.$store.dispatch('logout').then(this.$routerPush('/'))
        }
    }
}
</script>

<style lang="scss" scoped>
.user-info{
    padding: 30px 20px;
    display: flex;
    overflow: hidden;
    .avatar{
        width: 80px;
        height: 80px;
        object-fit: cover;
        background: #fff url('../../assets/no-pic.png') no-repeat center;
        border-radius: 50%;
        background-size: contain;
        position: relative;
        &:after{
            content:'';
            width: 60px;
            height: 60px;
            display: block;
            position: absolute;
            background: url('../../assets/yaya.gif') no-repeat center;
            background-size: contain;
            top: -35px;
            left: 8px;
        }
    }
    .user-name{
        line-height: 80px;
        padding-left: 20px;
    }
}
</style>